import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner4@2x.jpg'),
    heading: "If you're not an accountant you won't know what this is",
    subheading: "But if you are, you'll be pleased to hear that through Billsby Pro, your account can support automatic processing and reporting of your ASC 606 and IFRS 15 obligations with accuracy down to the day. We asked Paul, our CFO, for an accountancy joke to brighten up this copy. He said accountancy is no joke."
  }

  return(
    <Layout>
      <SEO 
        title="Revenue Recognition | Billsby Pro | Take your subscription business to the next level" 
        description="Revenue Recognition and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/revenue-recognition"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;